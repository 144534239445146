<template>
    <Image src="layout/images/logo-dsg.svg" alt="Logo formando a palavra Duon" width="100" class="mt-6" />
    <span class="font-bold mt-4 poppins-medium" style="font-size: 28px"> Confirmar seus dados </span>
    <span class="text-center mt-2" style="font-size: 18px"> Para fazer suas preferencias na consulta ocupacional confirme seus dados </span>
    <div class="mt-5 w-full">
        <label class="text-2xl text-700" for="name"> Nome </label>
        <InputText id="name" v-model="colaborador.name" class="w-full border-2 border-round-xl p-4 mt-1 text-xl font-medium" type="text" />
    </div>
    <div class="mt-4 w-full">
        <label class="text-2xl text-700" for="dataNascimento"> Data de nascimento </label>
        <Calendar
            id="dataNascimento"
            v-model="colaborador.dataNascimento"
            :maxDate="hoje"
            class="w-full mt-1"
            dateFormat="dd/mm/yy"
            inputClass="border-2 border-round-xl p-4 text-xl font-medium"
        />
    </div>
    <Button
        :disabled="!camposEstaoPreenchidos"
        :loading="loading"
        class="border-none text-xl w-full mt-5 py-3 poppins-medium"
        label="Confirmar dados"
        rounded
        style="background-color: #3f51b5"
        @click="salvarDadosDoColaborador()"
    />
    <span class="text-md text-800 mt-4"> Esse link expira no dia {{ dataExpiracao }} </span>
</template>

<script setup>
import dayjs from 'dayjs';
import { ref, defineEmits, onActivated, computed } from 'vue';
import { getClientBase } from '@/services/http';
import { getAutoagendamentoToken } from '@/common/storage';
import { useToast } from 'primevue/usetoast';
import { logoutAutoagendamento } from '@/services/auth';

const emit = defineEmits(['prevPage', 'nextPage']);

const autoagendamentoToken = getAutoagendamentoToken();
const auxHeaders = { Authorization: `Bearer ${autoagendamentoToken}` };

const hoje = dayjs().toDate();
const loading = ref(false);
const toast = useToast();
const dataExpiracao = ref('--/--/---');
const colaborador = ref({
    id: null,
    name: null,
    dataNascimento: null
});

const camposEstaoPreenchidos = computed(() => {
    return !!colaborador.value.name && !!colaborador.value.dataNascimento;
});

onActivated(() => {
    buscarDataExpiracao();
    buscarDadosDoColaborador();
});

async function buscarDadosDoColaborador() {
    loading.value = true;
    try {
        const { data } = await getClientBase(auxHeaders).get('/autoagendamento/colaborador');
        colaborador.value = {
            id: data.id,
            name: data.name,
            dataNascimento: dayjs(data.dataNascimento).toDate()
        };
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
async function buscarDataExpiracao() {
    loading.value = true;
    try {
        const { data } = await getClientBase(auxHeaders).get('/autoagendamento/data-sugestao');
        dataExpiracao.value = dayjs(data.dataSugestaoFinal).format('DD/MM/YYYY');
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
async function salvarDadosDoColaborador() {
    loading.value = true;
    try {
        await getClientBase(auxHeaders).patch('/autoagendamento/colaborador', colaborador.value);
        emit('nextPage');
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
</script>
